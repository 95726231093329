import React from "react";
import {isMobile} from 'react-device-detect';

class SendMessageForm extends React.Component{

    constructor() {
        super();
        this.state = {
            msg:''
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.onEnterPress = this.onEnterPress.bind(this)
    }

    handleChange(e){
        this.setState({
            msg: e.target.value
        })
    }

    handleSubmit(e){
        e.preventDefault()
        console.log(this.state.msg)
        this.props.sendMessage(this.state.msg)
        this.setState({
            msg: ''
        })
    }

    onEnterPress(e){
        if(e.keyCode === 13 && e.shiftKey === false) {
            this.handleSubmit(e)
        }else if(e.keyCode === 8 && e.shiftKey === true){
            this.setState({
                msg: ''
            })
        }else{
            this.handleChange(e)
        }
    }

    render() {
        return(
            <form
                onSubmit={this.handleSubmit}
                className="SendMessageForm">
                <textarea
                    className={'TypeArea'}
                    onChange={this.handleChange}
                    onKeyDown={this.onEnterPress}
                    value={this.state.msg}
                    aria-label={"Text to send to chatbot"}
                    placeholder={!isMobile ? "Type Your Message Here... (Shift+Backspace to Erase)" : "Type Your Message Here..."}
                    type="text"/>
                <input type="submit" className={'Submit'} value={'Send'}/>
            </form>
        )
    }
}

export default SendMessageForm;