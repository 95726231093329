import React from "react";

class Rooms extends React.Component{



    render() {
        return (
            <div className="Rooms">
                {this.props.rooms.map((room, index) => {
                    if(room.active) {
                        if(this.props.activeRoomName === room.roomID) {
                            return (
                                <button key={index} className={'Active'} id={room.roomID} onClick={(e) => {
                                    this.props.changeActiveRoom(e.currentTarget.id)
                                }}>{room.roomID}</button>
                            )
                        }else{
                            return (
                                <button key={index} id={room.roomID} onClick={(e) => {
                                    this.props.changeActiveRoom(e.currentTarget.id)
                                }}>{room.roomID}</button>
                            )
                        }
                    }
                })}
            </div>
        );
    }
}

export default Rooms;