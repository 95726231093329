import React from 'react';
import Demo from './components/axiosExample';
import SendMessageForm from "./components/SendMessageForm";
import MessageList from './components/MessageList';
import Rooms from './components/Rooms';
import axios from "axios";
import'./style.css';
import MenuIcon from '@mui/icons-material/Menu';
import {isMobile} from 'react-device-detect';

/*ngrok http 80 --host-header="localhost:80"*/

class App extends React.Component{

    constructor() {
        super();
        this.state = {
            messages:[],
            rooms:[],
            activeRoom:"Tester",
            showSideBar: false,
            UID: ""
        }
        this.sendMessage = this.sendMessage.bind(this)
    }

    async componentDidMount(){
        await axios.get('/reset')
            .then(async res => {
                console.log(res.request.response);
                this.setState({UID: res.request.response})
            })
            .catch(err => {console.log(err)})
        this.loadData().then(res => (console.log(res)))

        if(!isMobile){
            this.setState({showSideBar: true})
        }
    }

    changeActiveRoom(text){
        this.setState({activeRoom: text});
    }

    async sendMessage(text, room){
        await axios.post('/post', {'msg': text, 'room': this.state.activeRoom, 'UID': this.state.UID})
            .then(res => console.log(res.request.response))
            .catch(err => {console.log(err)})
        await this.loadData()
    }

    async loadData(){
        await axios.get('/getMessages', { params: { UID: this.state.UID } })
            .then(res => {
                let data = JSON.parse(res.request.response)
                const arr = []
                data.forEach((i)=>{
                    let j = JSON.parse(i)
                    arr.push({roomID: j.roomID, senderID: j.senderID, text: j.text})
                })
                this.setState({messages: arr})
            })
            .catch(err => {console.log(err)})

        await axios.get('/getRooms')
            .then(res => {
                let data = JSON.parse(res.request.response)
                const arr = []
                data.forEach((i)=>{
                    let j = JSON.parse(i)
                    arr.push({roomID: j.roomID, active: j.active})
                })
                this.setState({rooms: arr})
            })
            .catch(err => {console.log(err)})
    }

    /*[...olddata, newdata] <- this is how you add to an array*/

    render() {

        return(
        <div className="App" aria-label={"main"}>
            <div className={"navbar"} aria-label={"navbar"}>
                <div className={"icon"}>
                    <span role={"heading"} className={"material-symbols-outlined"} onClick={() => {this.setState({showSideBar: !this.state.showSideBar});}}>
                        menu
                    </span>
                </div>
                <h1>Trust and Chatbot Investigation: Testing System</h1>
            </div>

            <div className={"Content"}>

                {this.state.showSideBar && <div className={"sidebar"}>
                    <div className={"Left"}>
                        <h2>Rooms</h2>
                        <Rooms rooms={this.state.rooms} activeRoomName={this.state.activeRoom} changeActiveRoom={this.changeActiveRoom.bind(this)}/>
                    </div>
                </div>}

                {isMobile && !this.state.showSideBar && <div className={"Right"}>
                    <h2>{'Chatroom: ' + this.state.activeRoom}</h2>
                    <MessageList messages={this.state.messages} activeRoomName={this.state.activeRoom}/>
                    <SendMessageForm sendMessage={this.sendMessage}/>
                </div>}

                {!isMobile && <div className={"Right"}>
                    <h2>{'Chatroom: ' + this.state.activeRoom}</h2>
                    <MessageList messages={this.state.messages} activeRoomName={this.state.activeRoom}/>
                    <SendMessageForm sendMessage={this.sendMessage}/>
                </div>}

            </div>
        </div>
        )
    };
}

export default App;
