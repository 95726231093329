import React from 'react'
import axios from 'axios'

class Demo extends React.Component {

    constructor() {
        super();
        this.state = {
            msg:''
        }
    }

    render() {

        let handleChange = (e) =>{
            this.setState({msg: e.target.value})
        }

        let handleClick = () =>{
            if(this.state.msg !== '') {
                axios.post('/user', {'msg': this.state.msg })
                    .then(res=>console.log(res.request.response))
                    .catch(err=>{console.log(err)})
            }
        }

        return (
            <div className="App">
                <div>
                    <h2>header</h2>
                </div>
                <div>
                    <h1>messages</h1>
                </div>
                <div>
                    <input type={'text'} name={'msgBox'}
                           onChange={(e) => handleChange(e)}
                           value={this.state.msg}/>
                    <button onClick={() => handleClick()}>Submit</button>
                </div>
            </div>
        )
    }
}

export default Demo;
