import React from "react";

class Message extends React.Component{


    render() {

        return (
            <div className={this.props.senderID}>
                <div className={'Bubble'} id={this.props.senderID}>
                    <div className={'Text'}>{this.props.text}</div>
                    <div className={'Username'}>{this.props.senderID}</div>
                </div>
            </div>
        );
    }
}

export default Message;