import React from "react";
import ReactDOM from 'react-dom';
import Message from "./Message";

class MessageList extends React.Component{

    componentDidUpdate(prevProps, prevState, snapshot) {
        const node = ReactDOM.findDOMNode(this)
        node.scrollTop = node.scrollHeight
    }


    render() {
        return (
            <div className="MessageList">
                {this.props.messages.map((message, index) => {
                    if (message.roomID === this.props.activeRoomName) {
                        return (
                            <Message key={index} senderID={message.senderID} text={message.text}/>
                        )
                    }
                })}
            </div>
        );
    }
}

export default MessageList;